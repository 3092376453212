<template>
  <div :class="[className]">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-customs', { error: v && v.$error }]">
      <label v-if="textFloat" class="title">
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger">*</span>
      </label>
      <div class="datetime-container">
        <datepicker
          :type="type"
          :placeholder="placeholder"
          value-zone="local"
          :value="value"
          :max-datetime="maxDatetime"
          :min-datetime="minDatetime"
          @input="handleFormat"
          @opened="test"
          :format="format"
          :language="th"
          buddhist
          ref="datepicker"
          :disabled="disabled"
        />
        <div  class="icon-calendar">
          <font-awesome-icon
            @click="$refs.datepicker.showCalendar()"
            icon="calendar-alt"
            class="pointer color-primary"
          />
        </div>
      </div>
    </div>
    <span v-if="detail" class="text-desc text-wrap">{{ detail }}</span>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">กรุณากรอกข้อมูล</span>
      <span class="text-error" v-else-if="v.minValue == false"
        >วันสิ้นสุดต้องมากกว่าหรือเท่ากับวันเริ่มต้น
      </span>
      <span class="text-error" v-else-if="v.maxValue == false"
        >วันเริ่มต้นต้องน้อยกว่าหรือเท่ากับวันสิ้นสุด
      </span>
      <span class="text-error" v-else-if="v.maxValueToday == false"
        >วันเริ่มต้นต้องน้อยกว่าหรือเท่ากับวันปัจจุบัน
      </span>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker-thai";
import { th } from "vuejs-datepicker-thai/dist/locale/index";

export default {
  props: {
    textFloat: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    value: {
      required: false,
      type: String,
    },
    minDatetime: {
      required: false,
      type: [String, Object],
    },
    maxDatetime: {
      required: false,
      type: [String, Object],
    },
    v: {
      required: false,
      type: Object,
    },
    detail: {
      required: false,
      type: String,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    placeholder: {
      required: false,
      type: String,
      // default: "DD/MM/YYYY",
    },
    className: {
      required: false,
      type: String,
    },
    type: {
      required: false,
      type: String,
      default: "date",
    },
    format: {
      required: false,
      type: String,
      default: "dd/MM/yyyy",
    },
    disabled: {
      required: false,
      type: Boolean,
    },
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      th: th,
    };
  },
  methods: {
    async handleFormat(event) {
      let date = null;
      if (this.type == "date") {
        date = event
          ? this.$moment(event).format("YYYY-MM-DDT00:00:00")
          : event;
      } else {
        date = event
          ? this.$moment(event).format("YYYY-MM-DDTHH:mm:ss")
          : event;
      }
      await this.$emit("input", date);
    },
    test(event) {
      console.log(event);
    },
  },
};
</script>

<style lang="scss" scoped>
.color-primary {
  color: var(--primary-color) !important;
}
.input-customs > input:focus {
  border: 1px solid var(--primary-color) !important;
}
.input-customs.error {
  .datetime-container {
    border-color: red !important;
  }

  .icon-calendar svg {
    color: red !important;
  }
}

::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
::v-deep .vdp-datepicker {
  display: block;
  border: none;
  width: 100%;
  input {
    width: 100%;
    border: 0 !important;
    background-color: white;
  }
}
.input-border-0 .datetime-container {
  border: 0 !important;
}
.text-desc {
  color: #9b9b9b;
  font-size: 12px;
}
.display-only {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.datetime-container {
  height: 35px;
  position: relative;
  background-color: white;
  border: 1px solid #bcbcbc;
  font-size: 14px !important;
  font-weight: bold !important;
  padding: 8px 10px !important;
  border-radius: 5px !important;
  .icon-calendar {
    position: absolute;
    top: 10px;
    right: 8px;
  }
}
.title {
  color: #333 !important;
  font-size: 14px !important;
  font-weight: bold !important;
  width: 100%;
  position: relative;
}
</style>
